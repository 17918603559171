<template>
  <div id="wrapper">
    <div style="padding-bottom: 70px;">
      <NavBar></NavBar>
    </div>
    <div class="container body-content">
      <div
        class="is-loading-bar"
        style="text-align:center;"
        v-bind:class="{ 'is-loading': $store.state.isLoading }"
      >
        <div class="lds-dual-ring"></div>
      </div>

      <section class="section">
        <router-view />
      </section>

      <footer class="footer">
        <Footer></Footer>
      </footer>
    </div>
  </div>
</template>

<script>
import NavBar from './components/Navbar'
import Footer from './components/Footer'
import { useStore } from 'vuex'

export default {
  data() {
    return {
      showMobileMenu: false,
    }
  },
  components: {
    NavBar,
    Footer,
  },
  beforeCreate() {},
  mounted() {
    const store = useStore()
  },
  computed: {},
}
</script>

<style lang="scss">
  .centered-content {
    display: flex; 
    justify-content: center; 
    justify-items: center; 
    align-items: center; 
    align-content: center;
  }
  
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.is-loading-bar {
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  &.is-loading {
    height: 80px;
  }
}
</style>
