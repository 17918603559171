import { createStore } from 'vuex'
import axios from 'axios'
import router from '../router/index.js'

export default createStore({
  state: {
    //on startup
    isLoading: false,

    isAuthenticated: undefined,
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,

    responseData: {},
    errors: [],
  },
  mutations: {
    setIsLoading(state, status) {
      state.isLoading = status
    },

    async initializeStore(state) {
      state.responseData = {}
      if (state.accessToken && state.refreshToken) {
        await this.dispatch('refreshToken')
      } else {
        await this.commit('removeTokens')
      }
    },

    setTokens(state, tokens) {
      try {
        if (tokens.accessToken) {
          state.accessToken = tokens.accessToken
          localStorage.setItem('accessToken', tokens.accessToken)
        }
        if (tokens.refreshToken) {
          state.refreshToken = tokens.refreshToken
          localStorage.setItem('refreshToken', tokens.refreshToken)
        }
        state.isAuthenticated = true
      } catch {
        this.commit('removeTokens')
      }
    },

    removeTokens(state) {
      state.accessToken = null
      state.refreshToken = null
      state.isAuthenticated = false
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
    },

    setResponseData(state, response) {
      state.responseData = response
    },
  },
  actions: {
    async putAPIResponse({ commit, state }, data) {
      let apiResponse = []
      commit('setIsLoading', true)
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + state.accessToken
      await axios
        .put(data.api, data.data)
        .then((response) => {
          apiResponse = response
          commit('setResponseData', response)
        })
        .catch((errors) => {
          console.log(errors.response);
          const error = []
          commit('setIsLoading', false)
          if (errors.response) {
            for (const property in errors.response.data) {
              error.push(`${property}: ${errors.response.data[property]}`)
            }
          } else {
            error.push('Something went wrong. Please try again')
          }
          apiResponse = errors.response

          if (errors.response.status == 401) {
            const msg = 'You are not authorised for this resource.'
            alert(msg)
            commit('setResponseData', msg)
            router.push('/')
          } else {
            commit('setResponseData', error)
          }
        })
      commit('setIsLoading', false)
      return apiResponse
    },

    async optionsAPIResponse({ commit, state }, api) {
      let apiResponse = []
      commit('setIsLoading', true)
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + state.accessToken
      await axios
        .options(api)
        .then((response) => {
          apiResponse = response.data.actions.POST
          commit('setResponseData', response)
        })
        .catch((error) => {
          apiResponse = error
          alert(error)
          commit('setResponseData', error)
          if (error.response.status == 401) {
            alert('You are not authorised for this resource.')
            router.push('/')
          }
        })
      commit('setIsLoading', false)
      return apiResponse
    },

    async getAPIResponse({ commit, state }, api) {
      let apiResponse = []
      commit('setIsLoading', true)
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + state.accessToken
      await axios
        .get(api)
        .then((response) => {
          apiResponse = response
          commit('setResponseData', response)
        })
        .catch((error) => {
          apiResponse = error
          commit('setResponseData', error)
          if (error.response.status == 401) {
            alert('You are not authorised for this resource.')
            router.push('/')
          }
        })
      commit('setIsLoading', false)
      return apiResponse
    },

    async postAPIData({ commit, state }, data) {
      console.log('api Post')
      let apiResponse = []
      commit('setIsLoading', true)
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + state.accessToken
      await axios
        .post(data.api, data.data)
        .then((response) => {
          apiResponse = response
          commit('setResponseData', response)
        })
        .catch((errors) => {
          apiResponse = errors

          const error = []
          commit('setIsLoading', false)
          if (errors.response) {
            for (const property in errors.response.data) {
              error.push(`${property}: ${errors.response.data[property]}`)
            }
          } else {
            error.push('Something went wrong. Please try again')
          }

          if (errors.response.status == 401) {
            const msg = 'You are not authorised for this resource.'
            alert(msg)
            commit('setResponseData', msg)
            router.push('/')
          } else {
            commit('setResponseData', error)
          }
        })

      commit('setIsLoading', false)
      return apiResponse
    },

    async deleteAPIData({ commit, state }, api) {
      console.log('api Delete')
      let apiResponse = []
      commit('setIsLoading', true)
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + state.accessToken

      await axios
        .delete(api)
        .then((response) => {
          apiResponse = response
          commit('setResponseData', response)
        })
        .catch((errors) => {
          apiResponse = errors

          const error = []
          commit('setIsLoading', false)
          if (errors.response) {
            for (const property in errors.response.data) {
              error.push(`${property}: ${errors.response.data[property]}`)
            }
          } else {
            error.push('Something went wrong. Please try again')
          }

          if (errors.response.status == 401) {
            const msg = 'You are not authorised for this resource.'
            alert(msg)
            commit('setResponseData', msg)
            router.push('/')
          } else {
            commit('setResponseData', error)
          }
        })

      commit('setIsLoading', false)
      return apiResponse
    },

    async blacklistToken({ commit, state }) {
      //logout
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + state.accessToken
      await axios
        .post('/auth/blacklist/', { refresh: state.refreshToken })
        .catch((error) => {
          console.log(error)
        })
      commit('removeTokens')
      router.push('/log-in')
    },

    async refreshToken({ commit, state }) {
      axios.defaults.headers.common['Authorization'] = ''
      const api = '/auth/refresh/'

      await axios
        .post(api, { refresh: state.refreshToken })
        .then((response) => {
          const tokens = {
            accessToken: response.data.access,
          }
          commit('setTokens', tokens)
        })
        .catch((error) => {
          commit('removeTokens')
          console.log(error)
          alert('Your session has expired. Please log in again.')
          router.push('/log-in')
        })
    },

    async login({ commit, state }, formData) {
      commit('setIsLoading', true)
      await axios
        .post('/auth/login/', formData)
        .then((response) => {
          console.log('logged in successfully')
          commit('setResponseData', response)
          const tokens = {
            accessToken: response.data.access,
            refreshToken: response.data.refresh,
          }
          commit('setTokens', tokens)
        })
        .catch((errors) => {
          console.log(errors)
          commit('setResponseData', errors)
          if (errors.response.status == 401) {
            alert('Your login details appear incorrect, please try again.')
          } else {
            alert(errors)
          }
        })
      commit('setIsLoading', false)
    },

    async createForm({ state }, obj) {
      const list = obj.list
      const doc = obj.doc
      const parent = obj.parent || undefined
      const id = obj.id || undefined
      var el = undefined

      let form = {}
      if (id) {
        form['id'] = id
      }

      await Object.entries(list).forEach((entry) => {
        const [key, value] = entry
        let subForm = {}
        el = undefined
        if (value.querySet) {
          
          if (parent != undefined) {
            el = doc.querySelector(`#${parent} #${key}`)
          } else {
            el = doc.getElementById(key)
          }
          if (!!el.value) {
            subForm['id'] = el.value
          }
        }
        if (value.type == 'nested object') {

          Object.entries(value.children).forEach((subEntry) => {
            const [subKey, subValue] = subEntry
            if (parent != undefined) {
              el = doc.querySelector(`#${parent} #${subKey}`)
            } else {
              el = doc.getElementById(subKey)
            }
            if (!!el.value) {
              if(el.value !== "") {
                subForm[subKey] = el.value
              }
              else {
                subForm[subKey] = null
              }
            }
            else {
              subForm[subKey] = null
            }
          })

          form[key] = subForm
        } 
        else {
          if (parent != undefined) {
            el = doc.querySelector(`#${parent} #${key}`)
          } else {
            el = doc.getElementById(key)
          }
          try {
            if (el.type == 'checkbox') {
              form[key] = el.checked

            }
            else {
              if(el.value !== "") {
                form[key] = el.value
              }
              else {
                form[key] = null
              }
            }
          }
          catch {
            form[key] = null
          }
        }
      })
      return form
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!(state.accessToken && state.refreshToken)
    },

    getTokens(state) {
      return {
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
      }
    },

    getResponseData(state) {
      return state.responseData
    },

    getErrors(state) {
      return state.errors
    },
  },
  modules: {},
})
