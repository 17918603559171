<template>
  <div>
    <div id="breadcrumb" class="no-print">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Home' }">Home</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'plantPurchase' }"
              >Purchase Plant</router-link
            >
          </li>

          <li class="breadcrumb-item active" aria-current="page">
            Plant
          </li>
        </ol>
      </nav>
    </div>
    <div v-if="$store.state.isAuthenticated == true">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6">
          <table class="table">
            <thead>
              <tr>
                <th colspan="4">Plant</th>
              </tr>
              <tr>
                <th>Code</th>
                <th>Description</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="obj in responseData"
                v-bind:key="obj.id"
                v-bind:id="obj.id"
              >
                <td>{{ obj.code }}</td>
                <td>{{ obj.description }}</td>
                <td v-if="obj.active">Active</td><td v-else>Inactive</td>
                <td class="d-flex">
                  <formModal
                    :form="form"
                    :formObject="obj"
                    header="Update Plant"
                    :putApi="'/plant/'+obj.id + '/'"
                  />

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div v-else-if="$store.state.isAuthenticated == false">
      401 Forbidden
    </div>
  </div>
</template>

<script js>
import { useStore } from 'vuex'
import formModal from '@/views/App/components/form_modal'

export default {
  name: 'plantList',
  data() {
    return {
      store: useStore(),
      api: '/plant/',
      responseData: {},
      errors: [],
      form: [],
    }
  },
  components: {
    formModal,
  },
  async mounted() {
    document.title = "Plant List | Ronnie's Place"
    this.form = await this.store.dispatch('optionsAPIResponse', this.api)
    this.getQuerySet()

  },
  methods: {
    async getQuerySet() {
      this.responseData = await this.store.dispatch('getAPIResponse', this.api)

      if (this.responseData.status == 200) {
        this.responseData = this.responseData.data
      } else {
        this.errors = this.responseData.data
      }
    },

    async getQuerySet() {
      this.responseData = await this.store.dispatch('getAPIResponse', `${this.api}?active=True`)

      if (this.responseData.status == 200) {
        this.responseData = this.responseData.data
      } else {
        this.errors = this.responseData.data
      }
    },


  }
}
</script>
