<template>
  <div>
  <strong style="font-size:24px;">AI Solutions: </strong>
  </div>
  <br />

  <h4>About:</h4>
  <p>We solve problems you didn't know you have in ways anyone can understand.</p>

  <p>We specialize in any accounting and/or I.T related solutions, especially in designing internal controls and new systems</p>

  <h4>Contact Us:</h4>
  <address>
      <strong>Rudolf Jacobs (Director): <a href="tel:+27-78-802-7374">(+27) 78 802 7374</a></strong>
  </address>

  <h4>Email us:</h4>
  <address>
      <strong>Rudolf Jacobs:</strong> <a href="mailto:rudolf@a-i-solutions.co.za/" target="_blank">rudolf@a-i-solutions.co.za/</a><br />
      <strong>Account related queries:</strong> <a href="mailto:accounts@a-i-solutions.co.za/" target="_blank">accounts@a-i-solutions.co.za/</a><br />
  </address>
</template>


<script>
export default {
  name: 'About',
    mounted() {
        document.title = 'About | Practice'
    },
}
</script>,