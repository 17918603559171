<template>
  <br/>
  <div class="no-print" style="padding-top:100px;">
    <hr/>
      <img src="@/assets/logo.png" />
      <p>&copy; 2022 - <router-link :to="{ name: 'about'}" class="navbar-brand">AI Solutions (Pty) Ltd</router-link></p>
      
  </div>
</template>

<script>
  export default {
    name: 'Footer',
  }
</script>

