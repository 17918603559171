<template>
  <div class="col-md-8">
    <section id="loginForm">
      <form @submit.prevent="submitForm">
        <h4>Use a local account to log in.</h4>
        <hr />

        <div class="mb-3">
          <label for="id_username" class="form-label" style="font-weight:bold;"
            >User name</label
          >
          <div class="col-md-10">
            <input
              id="id_username"
              type="text"
              class="form-control"
              v-model="username"
            />
          </div>
        </div>

        <div class="mb-3">
          <label for="id_password" class="form-label" style="font-weight:bold;"
            >Password</label
          >
          <div class="col-md-10">
            <input
              id="id_password"
              type="password"
              class="form-control"
              v-model="password"
            />
          </div>
        </div>

        <div class="field">
          <div class="control">
            <button class="btn btn-primary">Log in</button>
          </div>
        </div>

        <div class="notification is-danger" v-if="error">
          <p class="mt-2">{{error}}</p>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
export default {
  name: 'LogIn',
  data() {
    return {
      username: '',
      password: '',
      accessToken: null,
      refreshToken: null,
      error: null,
    }
  },
  mounted() {
    document.title = "Log In | Ronnie's Place"
  },
  methods: {
    async submitForm() {
      const formData = {
        username: this.username,
        password: this.password,
      }
      await this.$store.dispatch('login', formData)
      const responseData = this.$store.getters.getResponseData

      if (responseData.status == 200) {
        this.$router.push('/dashboard/')
      } else {
        this.error = "Please check your username and password."
      }
    },
  },
}
</script>
