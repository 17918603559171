<template>
  <template
    v-for="(field, key) in form"
    v-bind:key="field.label"
    v-bind:id="key"
  >
    <template v-if="field.type == 'nested object'">
      <template v-if="field.querySet">
        <label :for="key">{{ field.label }}:</label>
        <createInput
          :field="field"
          :fieldKey="key"
        />

      </template>

      <template v-else>
        <template
          v-for="(subField, subKey) in field.children"
          v-bind:key="subKey"
          v-bind:field="subField"
        >
          <label v-if="subField.label != 'ID'" 
          :for="subKey">
            {{ field.label }}: {{ subField.label }}
          </label>
          <createInput v-if="formObject"
            :field="subField"
            :fieldKey="subKey"
            :value="formObject[key][subKey]"
          />
          <createInput v-else
            :field="subField"
            :fieldKey="subKey"
          />

          <div v-if="errors">
            <div class="alert-danger p-0 m-2" v-if="errors[key][subKey]">
              <li class="mt-2">{{ errors[key][subKey].toString() }}</li>
            </div>
          </div>
        </template>
      </template>
    </template>

    <template v-else>
      <label v-if="field.label != 'ID'" :for="key">{{ field.label }}</label>
      <createInput v-if="formObject"
        :field="field"
        :fieldKey="key"
        :value="formObject[key]"
      />
      <createInput v-else
        :field="field"
        :fieldKey="key"
      />

      <template v-if="errors">
        <div class="alert-danger p-0 m-2" v-if="errors[key]">
          <li class="mt-2">{{ errors[key].toString() }}</li>
        </div>
      </template>
    </template>

  </template>
</template>
<style>
label {
  font-weight: bold;
}
</style>

<script>
import createInput from '@/views/App/components/create_input'
export default {
  name: 'formInput',
  props: {
    form: undefined,
    errors: undefined,
    formObject: undefined,
  },

  components: {
    createInput,
  },

}
</script>
