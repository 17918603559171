<template >
  <div v-if="$store.state.isAuthenticated == true">

    <div id="breadcrumb" class="no-print">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" >
          <li class="breadcrumb-item centered-content">
            <router-link :to="{ name: 'Home' }">Home</router-link>
          </li>
          <li class="breadcrumb-item centered-content">
            <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item centered-content">
            <router-link :to="{ name: 'plantPurchase' }"
              >Purchase Plant</router-link
            >
          </li>
          <li class="breadcrumb-item centered-content">
            <router-link :to="{ name: 'workList' }"
              >Work Permit List</router-link
            >
          </li>
          <li class="breadcrumb-item active centered-content" aria-current="page" >Work Detail</li>
        </ol>
      </nav>
    </div>
    <div id="main content">
      <div class='row centered-content'>
        <div class='col-12 col-sm-12 col-md-8 col-lg-6 col-xl-4 col-xxl-4'>
          <div class='card'>
            <div class='card-header'>
              <h3 style="text-align: center;">
                Work Detail 
                <formModal
                :form="form"
                :formObject="object"
                :header="'Update Work Permit'"
                :putApi="api + object.id + '/'"
              />
              </h3>
              <p><strong>Status: </strong><template v-if="object.completed === true">Complete</template><template v-else>Active</template></p>
            </div>
            <div class='card-body'>
              <p><strong>Date: </strong>{{ $filters.date(object.date) }}</p>
              <p><strong>Description: </strong>{{ object.description }}</p>
              <p v-if="object.plant"><strong>Plant: </strong>{{ object.plant.code }}: {{ object.plant.description }}</p>
              <p v-if="object.contractor"><strong>Contractor: </strong>{{ object.contractor.description }}</p>
              <p v-if="object.location"><strong>Location: </strong>{{ object.location.description }}</p>
              <p><strong>Machine Start Hours: </strong>{{ object.machine_start_hours }}</p>
              <p><strong>Machine End Hours: </strong>{{ object.machine_end_hours }}</p>
              <p><strong>Time Start: </strong>{{ object.time_start }}</p>
              <p><strong>Time End: </strong>{{ object.time_end }}</p>
              <p><strong>Diesel Add: </strong>{{ object.diesel_add }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    401 Forbidden
  </div>
</template>

<style>
  p {
    text-align: center;
  }
</style>

<script js>
import { useStore } from 'vuex'
import formModal from '@/views/App/components/form_modal'

export default {
  name: 'workDetail',
  data() {
    return {
      store: useStore(),
      api: '/work/',
      object: {},
      errors: undefined,
      form: {},
    }
  },
  components: {
    formModal,
  },
  async mounted() {
    document.title = "Work Permits Detail | Ronnie's Place"
    this.getObject()
    this.getForm()
  },
  methods: {
    async getObject() {
      const responseData = await this.store.dispatch('getAPIResponse', `${this.api}${this.$route.params.id}/`) //object
      if (responseData.status == 200) {
        this.object = responseData.data
      } else {
        this.errors = responseData.data
      }
    },
    async getForm() {
      // try {
      const plantData = await this.store.dispatch('getAPIResponse', '/plant/') //plant dropdown qs
      const contractorData = await this.store.dispatch('getAPIResponse','/contractor/') //plant dropdown qs
      const locationData = await this.store.dispatch('getAPIResponse','/location/') //location dropdown qs

      this.form = await this.store.dispatch('optionsAPIResponse', this.api)

      if (plantData.status == 200) {
        let fPlant = []
        plantData.data.forEach((element) => {
          fPlant.push({
            id: element.id,
            description: `${element.code}: ${element.description}`,
          })
        })
        this.form.plant.querySet = fPlant
      }
      if (contractorData.status == 200) {
        let fContractor = []
        contractorData.data.forEach((element) => {
          fContractor.push({
            id: element.id,
            description: `${element.description}`,
          })
        })
        this.form.contractor.querySet = fContractor
      }
      if (locationData.status == 200) {
        let fLocation = []
        locationData.data.forEach((element) => {
          try{
            fLocation.push({
              id: element.id,
              description: `${element.description}`,
            })
          }
          catch {
          }
        })
        this.form.location.querySet = fLocation
      }
    },
  },
}
</script>
