import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'

import Home from '../views/App/Templates/Home.vue'
import About from '../views/App/Templates/About.vue'
import LogIn from '../views/App/Templates/LogIn.vue'

import PlantRoutes from '../views/Plant/urls.js'
import purchaseRoutes from '../views/Purchase/urls.js'
import homeRoutes from '../views/Home/urls.js'
import workRoutes from '../views/Work/urls.js'
import contractorRoutes from '../views/Contractor/urls.js'
import locationRoutes from '../views/Location/urls.js'

const baseRoutes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/about', name: 'about', component: About },
  { path: '/log-in', name: 'LogIn', component: LogIn },
]

const routes = baseRoutes
  .concat(PlantRoutes)
  .concat(purchaseRoutes)
  .concat(homeRoutes)
  .concat(workRoutes)
  .concat(contractorRoutes)
  .concat(locationRoutes)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  await store.commit('initializeStore')
  next()
})

export default router
