<template>
  <div class="p-0 m-0" v-if="form">
    <div v-if="formObject">
      <span
        type="button"
        class="material-icons"
        data-bs-toggle="modal"
        :data-bs-target="'#form_modal' + formObject.id"
        >create</span
      >
      <!-- Form MODAL -->
      <div :id="'form_modal' + formObject.id" class="modal fade" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <form @submit.prevent="update(formObject.id)">
              <div class="modal-header">
                <h4 class="modal-title">{{ header }}</h4>
                <button
                  type="button"
                  class="btn btn-lg"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                >
                  &times;
                </button>
              </div>

              <div :id="'formDiv' + formObject.id" class="modal-body">
                <formInput
                  :form="form"
                  :errors="errors"
                  :formObject="formObject"
                />
              </div>

              <div class="modal-footer">
                <button type="submit" class="btn btn-primary">
                  {{ header }}
                </button>

                <button
                  type="button"
                  class="btn btn-default"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Form MODAL -->
    </div>
    <div v-else>

      <span
        type="button"
        class="material-icons"
        data-bs-toggle="modal"
        :data-bs-target="'#form_modal' + modalID"
        >create</span>
      <!-- Form MODAL -->
      <div :id="'form_modal' + modalID" class="modal fade" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <form @submit.prevent="create(modalID)">
              <div class="modal-header">
                <h4 class="modal-title">{{ header }}</h4>
                <button
                  type="button"
                  class="btn btn-lg"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                >
                  &times;
                </button>
              </div>

              <div :id="'formDiv' + modalID" class="modal-body">
                <formInput
                  :form="form"
                  :errors="errors"
                ></formInput>
              </div>

              <div class="modal-footer">
                <button type="submit" class="btn btn-primary">
                  {{ header }}
                </button>

                <button
                  type="button"
                  class="btn btn-default"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Form MODAL -->
    </div>
  </div>
</template>
<style>
label {
  font-weight: bold;
}
</style>

<script>
import { useStore } from 'vuex'
import formInput from '@/views/App/components/formInput'

export default {
  name: 'formModal',
  props: {
    form: {},
    formObject: undefined,
    modalID: undefined,
    header: '',
    putApi: '',
  },
  components: {
    formInput,
  },
  data() {
    return {
      store: useStore(),
      inputObj: {} || null,
      errors: undefined,
      formForSubmit: {},
      responseData: {},
    }
  },
  methods: {
    async update(id) {
      const payload = {
        list: this.form,
        doc: document,
        parent: `formDiv${id}`,
        id: id,
      }
      this.formForSubmit = await this.store.dispatch('createForm', payload)
      const api = {
        api: this.putApi,
        data: this.formForSubmit,
      }
      this.responseData = await this.store.dispatch('putAPIResponse', api)

      if (this.responseData.status == 400) {
        this.errors = this.responseData.data
        console.log('error', this.responseData)
      } else if (this.responseData.status == 200) {
        window.location.reload()
      }
    },
    async create(div) {
      const payload = {
        list: this.form,
        doc: document,
        parent: `formDiv${div}`,
      }
      this.formForSubmit = await this.store.dispatch('createForm', payload)
      const api = {
        api: this.putApi,
        data: this.formForSubmit,
      }
      this.responseData = await this.store.dispatch('postAPIData', api)

      if (this.responseData.status == 400) {
        this.errors = this.responseData.data
        console.log(this.errors)
      } else if (this.responseData.status == 201) {
        window.location.reload()
      }
    },
  },
}
</script>
