import plantPurchase from './purchase.vue'
import purchaseDetail from './detail.vue'
import purchaseList from './list.vue'

const purchaseRoutes = [
    {path:'/purchase/', name: 'purchaseList', component: purchaseList},
    {path:'/purchase/:id/', name: 'purchaseDetail', component: purchaseDetail},
    {path:'/purchase/create/', name: 'plantPurchase', component: plantPurchase},
]

export default purchaseRoutes