<template>
  <h3>Welcome to Plant Management System</h3>
    <a v-if="$store.state.isAuthenticated" class="btn btn-primary" href="/dashboard/">View Dashboard</a>
    <router-link v-else class="btn btn-primary" :to="{ name: 'LogIn' }">Login</router-link>
</template>

<script>
import { useStore } from 'vuex'

export default {
  name: 'Home',
  components: {},
  mounted() {
    const store = useStore()
    document.title = 'Home | Practice'
  },
}
</script>
