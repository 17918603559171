<template>
  <div>
    <div id="breadcrumb" class="no-print">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Home' }">Home</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'plantList' }">Plant</router-link>
          </li>

          <li class="breadcrumb-item active" aria-current="page">
            Purchase Plant
          </li>
        </ol>
      </nav>
    </div>

    <div v-if="$store.state.isAuthenticated == true">
      <div
        class="row"
        style="display: grid; 
          flex-direction: column;
          justify-items: center;
          "
      >
        <div class="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6">
          <form @submit.prevent="create">
            <div class="card">
              <div class="card-header">
                <h4>Purchase Plant</h4>
              </div>
              <div class="card-body">
                <formInput :form="form" :errors="errors"></formInput>
              </div>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary">
                  Create Purchase
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-else-if="$store.state.isAuthenticated == false">
      401 Forbidden
    </div>
  </div>
</template>

<script js>
import { useStore } from 'vuex'
import formInput from '@/views/App/components/formInput'

export default {
  name: 'plantPurchase',
  data() {
    return {
      responseData: {},
      errors: null,
      api: '/purchase/',
      form: {},
      formForSubmit: [],
      store: useStore(),
    }
  },
  components: {
    formInput,
  },
  async mounted() {
    document.title = "Purchase Plant | Ronnie's Place"
    this.form = await this.store.dispatch('optionsAPIResponse', this.api)
  },

  methods: {
    async create() {
      const payload = {
        list: this.form,
        doc: document,
      }
      this.formForSubmit = await this.store.dispatch('createForm', payload)
      const api = {
        api: '/purchase/',
        data: this.formForSubmit,
      }
      this.responseData = await this.store.dispatch('postAPIData', api)
      if (this.responseData.status == 400) {
        this.errors = this.responseData.data
      } 
      else if (this.responseData.status == 201) {
        this.$router.push({
          name: 'plantDetail',
          params: { id: this.responseData.data.plant.id },
        })
      } 



    },
  },
}
</script>
