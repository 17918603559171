<template>
  {{queryset}}
  <div id="breadcrumb" class="no-print">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Home' }">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active d-flex" aria-current="page">
          Add Location
          <formModal :form="form" :header="'Create Location'" :putApi="api" />
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Locations
        </li>
      </ol>
    </nav>
  </div>
  <div v-if="$store.state.isAuthenticated == true">
    <template v-if="querySet.length > 0">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6">
          <table class="table">
            <thead>
              <tr>
                <th>Location</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="obj in querySet" :key="obj.id">
                <tr>
                  <td>{{ obj.description }}</td>
                  <td v-if="obj.active">Active</td><td v-else>Inactive</td>
                  <td class="d-flex">
                    <formModal
                      :form="form"
                      v-bind:formObject="obj"
                      :header="'Update Location'"
                      :putApi="api + obj.id + '/'"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </template>

    <template v-else>
      <h3>No locations</h3>
      <div class="d-flex mt-2">
        <span>Add Location: </span>
        <formModal :form="form" :header="'Create Location'" :putApi="api" />
      </div>
    </template>
  </div>

  <div v-else-if="$store.state.isAuthenticated == false">
    401 Forbidden
  </div>
</template>

<script js>
import { useStore } from 'vuex'
import formModal from '@/views/App/components/form_modal'

export default {
  name: 'locationList',
  data() {
    return {
      store: useStore(),
      api: '/location/',
      responseData: {},
      querySet: {},
      errors: undefined,
      form: {},
    }
  },
  components: {
    formModal,
  },
  async mounted() {
    document.title = "Location List | Ronnie's Place"
    this.getInitialQuerySet()
    this.getForm()
  },
  methods: {
    async getInitialQuerySet() {
      this.responseData = await this.store.dispatch('getAPIResponse', `${this.api}?all=True`) //qs

      if (this.responseData.status == 200) {
        this.querySet = this.responseData.data
      } else {
        this.errors = this.responseData.data
      }
    },
    
    async getForm() {
      this.form = await this.store.dispatch('optionsAPIResponse', this.api)
    },
  },
}
</script>
