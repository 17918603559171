<template>
  <div>
    <div id="breadcrumb" class="no-print">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Home' }">Home</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'plantList' }">All Plant</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'plantPurchase' }"
              >Purchase Plant</router-link
            >
          </li>

          <li class="breadcrumb-item active" aria-current="page">
            Plant Detail
          </li>
        </ol>
      </nav>
    </div>

    <div v-if="$store.state.isAuthenticated == true">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6">
          <table class="table">
            <thead>
              <tr>
                <th>Purchase Detail</th>
                <th>
                  <formModal
                    :form="form"
                    :object="object"
                    header="Update Plant"
                    api="plant"
                  ></formModal>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Code</td>
                <td>{{ object.code }}</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>{{ object.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else-if="$store.state.isAuthenticated == false">
      401 Forbidden
    </div>
  </div>
</template>

<script js>
import { useStore } from 'vuex'
import formModal from '@/views/App/components/form_modal'

export default {
  name: 'purchaseDetail',
  data() {
    return {
      store: useStore(),
      responseData: {},
      object: {},
      errors: [],
      form: [],
      getApi: `/plant/${this.$route.params.id}/`,
      optApi: `/plant/`,
    }
  },
  components: {
    formModal,
  },
  async mounted() {
    document.title = "Purchase Detail | Ronnie's Place"
    this.responseData = await this.store.dispatch('getAPIResponse', this.getApi)
    if (this.responseData.status == 200) {
      this.object = this.responseData.data
    } else if (this.responseData.response.status) {
      alert('Plant not found')
      this.$router.push({
        name: 'plantList',
      })
    } else {
      this.errors = this.responseData.data
    }
    this.form = await this.store.dispatch('optionsAPIResponse', this.optApi)
  },
}
</script>
