<template>
  <div id="breadcrumb" class="no-print">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Home' }">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'plantPurchase' }"
            >Purchase Plant</router-link
          >
        </li>

        <li class="breadcrumb-item active" aria-current="page">
          Plant
        </li>
      </ol>
    </nav>
  </div>
  <div v-if="$store.state.isAuthenticated == true">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6">
        <table class="table">
          <thead>
            <tr>
              <th colspan="4">Purchases</th>
            </tr>
            <tr>
              <th>Date</th>
              <th>Plant</th>
              <th>Cost</th>
              <th>Cost Code</th>
              <th>Actions</th>

            </tr>
          </thead>
          <tbody>
            <template v-for="obj in queryset" :key="obj.id">
              <tr>
                <td>{{ obj.date }}</td>
                <td>{{obj.plant.code}}: {{obj.plant.description}}</td>
                <td>{{obj.price}}</td>
                <td>{{obj.cost_code}}</td>
                <td>
                  <formModal
                    :form="form"
                    v-bind:formObject="obj"
                    :header="'Update Purchase'"
                    :putApi="'/purchase/' + obj.id + '/'"
                  ></formModal>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div v-else-if="$store.state.isAuthenticated == false">
    401 Forbidden
  </div>
</template>

<script js>
import { useStore } from 'vuex'
import formModal from '@/views/App/components/form_modal'

export default {
  name: 'purchaseList',
  data() {
    return {
      store: useStore(),
      api: '/purchase/',
      responseData: {},
      queryset: {},
      errors: undefined,
      form: {},
    }
  },
  components: {
    formModal,
  },
  async mounted() {
    document.title = "Purchase List | Ronnie's Place"
    this.responseData = await this.store.dispatch('getAPIResponse', this.api)
    this.form = await this.store.dispatch('optionsAPIResponse', this.api)

    if (this.responseData.status == 200) {
      this.queryset = this.responseData.data
    } else {
      this.errors = this.responseData.data
    }
  },
}
</script>
