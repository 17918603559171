<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark static-top fixed-top">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <router-link :to="{ name: 'Home' }" class="navbar-brand"
        >Ronnie's Place</router-link
      >

      <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <template id="main_nav_div" v-if="$store.state.isAuthenticated == true">
          <ul class="nav navbar-nav mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link class="nav-link active" :to="{ name: 'dashboard' }">Dashboard</router-link>
            </li>
          </ul>
          <ul class="navbar-nav mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Plant
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <router-link :to="{ name: 'plantList' }" class="dropdown-item">
                    All Plant
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'workList' }" class="dropdown-item">
                    Historical Work
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
          <ul id="purchase_nav" class="navbar-nav mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Purchases
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <router-link
                    :to="{ name: 'plantPurchase' }"
                    class="dropdown-item"
                  >
                    Purchase plant
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ name: 'purchaseList' }"
                    class="dropdown-item"
                  >
                    All purchases
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
          <ul id="contractor_nav" class="navbar-nav mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Contractors
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <router-link
                    :to="{ name: 'contractorList' }"
                    class="dropdown-item"
                  >
                    All Contractors
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
          <ul id="location_nav" class="navbar-nav mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Locations
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <router-link
                    :to="{ name: 'locationList' }"
                    class="dropdown-item"
                  >
                    All Locations
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </template>
        <div class="d-flex ms-auto">
          <template v-if="$store.state.isAuthenticated == true">
            <div class="navbar-right">
              <ul class="nav navbar-nav navbar-right">
                <li class="nav-item nav-link active">Hello!</li>
                <li class="nav-item">
                  <a class="nav-link active" href="/admin/">Profile</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link active" @click="logout()">Log off</a>
                </li>
              </ul>
            </div>
          </template>
          <div v-else-if="$store.state.isAuthenticated == false">
            <ul class="nav navbar-nav navbar-right">
              <li class="nav-item">
                <router-link to="/log-in" class="navbar-brand"
                  >Log In</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {}
  },
  mounted() {},
  methods: {
    logout() {
      if (confirm('Do you really want to log out?')) {
        this.$store.dispatch('blacklistToken')
      }
    },
  },
  computed: {},
}
</script>

<style type="text/css">
a:hover {
  cursor: pointer;
}

@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }
  .navbar .nav-item:hover .nav-link {
    color: #fff;
  }
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }
  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}
</style>
