<template >
  <div v-if="$store.state.isAuthenticated == true">

    <div id="breadcrumb" class="no-print">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" >
          <li class="breadcrumb-item centered-content">
            <router-link :to="{ name: 'Home' }">Home</router-link>
          </li>
          <li class="breadcrumb-item centered-content">
            <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item centered-content">
            <router-link :to="{ name: 'plantPurchase' }"
              >Purchase Plant</router-link
            >
          </li>

          <li class="breadcrumb-item active centered-content" aria-current="page" >
            Historical Work
            <vue-excel-xlsx 
              v-if="querySet.length > 0"
              class="btn material-icons"
              :data="querySet"
              :columns="point_columns"
              :file-name="'Plant Work'"
              :file-type="'xlsx'"
              :sheet-name="'Plant Work'"
            >
            file_download
        </vue-excel-xlsx>
          </li>
        </ol>
      </nav>
    </div>
    <div id="main content">
      <div class="row">
        <h3>{{header}}</h3>
        <div class="col-12" v-if="querySet.length > 0">
          <table class="table">
            <thead>
              <tr>
                <th colspan="10">
                  <button class="btn btn-success me-2" @click="getActiveQuerySet()">View Active</button>
                  <button class="btn btn-danger me-2" @click="getCompletedQuerySet()">View Completed</button>
                  <button class="btn btn-primary me-2" @click="getEntireQuerySet()">View All</button>
                </th>
              </tr>
              <tr>
                <th>Plant</th>
                <th>Contractor</th>
                <th>Location</th>
                <th>Date</th>
                <th>Time start</th>
                <th>Time end</th>
                <th>Machine Start Hours</th>
                <th>Machine End Hours</th>
                <th>Diesel Add</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody v-if="querySet.length > 0">
              <template v-for="obj in querySet" :key="obj.id">
                <tr>
                  <td v-if="obj.plant">{{ obj.plant.code }}: {{ obj.plant.description }}</td><td v-else>None</td>
                  <td v-if="obj.contractor">{{ obj.contractor.description }}</td><td v-else>None</td>
                  <td v-if="obj.location">{{ obj.location.description }}</td><td v-else>None</td>
                  <td>{{ $filters.date(obj.date) }}</td>
                  <td>
                    <span v-if="obj.time_start">
                      {{ obj.time_start}}
                    </span>
                    <span v-else>-</span>
                  </td>
                  <td>
                    <span v-if="obj.time_end">
                      {{ obj.time_end }}
                    </span>
                    <span v-else>-</span>
                  </td>

                  <td>{{ obj.machine_start_hours }}</td>
                  <td>{{ obj.machine_end_hours }}</td>
                  <td>{{ obj.diesel_add }}</td>
                  <td class="centered-content">
                    <router-link target="_blank" class="material-icons" :to="'/work/'+obj.id+'/'">search</router-link>
                    <formModal
                      :form="form"
                      v-bind:formObject="obj"
                      :header="'Update Work Permit'"
                      :putApi="api + obj.id + '/'"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-else>
          <table class="table">
            <thead>
              <tr>
                <th colspan="10">
                  <button class="btn btn-success me-2" @click="getActiveQuerySet()">View Active</button>
                  <button class="btn btn-danger me-2" @click="getCompletedQuerySet()">View Completed</button>
                  <button class="btn btn-primary me-2" @click="getEntireQuerySet()">View All</button>
                </th>
              </tr>
            </thead>
          </table>

          <h3>No work permits currently</h3>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    401 Forbidden
  </div>
</template>

<style>

</style>

<script js>
import { useStore } from 'vuex'
import formModal from '@/views/App/components/form_modal'

export default {
  name: 'workList',
  data() {
    return {
      store: useStore(),
      api: '/work/',
      responseData: {},
      header: "",
      querySet: [],
      errors: undefined,
      form: {},
      point_columns: [
        {
          "label": "Plant",
          "field": "plant.code"
        },
        {
          "label": "Plant",
          "field": "plant.description"
        },
        {
          "label": "Contractor",
          "field": "contractor.description"
        },
        {
          "label": "Location",
          "field": "location.description"
        },
        {
          "label": "Date",
          "field": "date"
        },
        {
          "label": "Time Start",
          "field": "time_start"
        },
        {
          "label": "Time End",
          "field": "time_end"
        },
        {
          "label": "Machine Start Hours",
          "field": "machine_start_hours"
        },
        {
          "label": "Machine End Hours",
          "field": "machine_end_hours"
        },
        {
          "label": "Diesel Add",
          "field": "diesel_add"
        }
      ],
    }
  },
  components: {
    formModal,
  },
  async mounted() {
    document.title = "Work Permits List | Ronnie's Place"
    this.getActiveQuerySet()
    this.getForm()
  },
  methods: {
    async getActiveQuerySet() {
      this.header = "Active Plant"
      this.responseData = await this.store.dispatch('getAPIResponse', `${this.api}?completed=False`) //qs
      if (this.responseData.status == 200) {
        this.querySet = this.responseData.data
        this.cleanQuerySet()
      } else {
        this.errors = this.responseData.data
      }
    },
    async getCompletedQuerySet() {
      this.header = "Plant Work Completed"
      this.responseData = await this.store.dispatch('getAPIResponse', `${this.api}?completed=True`) //qs
      if (this.responseData.status == 200) {
        this.querySet = this.responseData.data
        this.cleanQuerySet()
      } else {
        this.errors = this.responseData.data
      }
    },
    async getEntireQuerySet() {
      this.header = "All Work"
      this.responseData = await this.store.dispatch('getAPIResponse', `${this.api}?all=True`) //qs

      if (this.responseData.status == 200) {
        this.querySet = this.responseData.data
        this.cleanQuerySet()
      } else {
        this.errors = this.responseData.data
      }
    },
    
    cleanQuerySet(){
      for (const obj of this.querySet) {
        if (!obj.location) {
          obj.location = {
            description:""
          }
        }
    }
    },
    async getForm() {
      // try {
      let plantData = await this.store.dispatch('getAPIResponse', '/plant/') //plant dropdown qs
      let contractorData = await this.store.dispatch('getAPIResponse','/contractor/') //plant dropdown qs
      let locationData = await this.store.dispatch('getAPIResponse','/location/') //location dropdown qs

      this.form = await this.store.dispatch('optionsAPIResponse', this.api)

      if (plantData.status == 200) {
        let fPlant = []
        plantData.data.forEach((element) => {
          fPlant.push({
            id: element.id,
            description: `${element.code}: ${element.description}`,
          })
        })
        this.form.plant.querySet = fPlant
      }
      if (contractorData.status == 200) {
        let fContractor = []
        contractorData.data.forEach((element) => {
          fContractor.push({
            id: element.id,
            description: `${element.description}`,
          })
        })
        this.form.contractor.querySet = fContractor
      }
      if (locationData.status == 200) {
        let fLocation = []
        locationData.data.forEach((element) => {
          try{
            fLocation.push({
              id: element.id,
              description: `${element.description}`,
            })
          }
          catch {
          }
        })
        this.form.location.querySet = fLocation
      }
      // }
      // catch {

      // }
    },
  },
}
</script>
