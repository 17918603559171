<template>
  <div id="breadcrumb" class="no-print">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'plantPurchase' }"
            >Purchase Plant</router-link
          >
        </li>
        <li class="breadcrumb-item d-flex">
          Send out plant
          <formModal
            :form="form"
            :header="'Send out plant'"
            :putApi="api"
            :modalID="'sendPlant'"
          />
        </li>

        <li class="breadcrumb-item active" aria-current="page">
          Home
        </li>
      </ol>
    </nav>
  </div>
  <div v-if="$store.state.isAuthenticated == true">
    <div class="row">
      <template v-if="querySet.length > 0">
        <h5>Current Plant in the field:</h5>
        <div
          v-for="obj in querySet"
          :key = obj.id
          class="col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4 col-xxl-4"
        >
          <div class="card mb-2">
            <div class="card-header" >
              <p><strong>Date: </strong>{{ obj.date }} <br>
                <strong>{{obj.contractor.description}}: {{ obj.description }}</strong>
              </p>
              <p>
                {{ obj.plant.code }} - {{ obj.plant.description }} @ {{obj.location.description}}
              </p>
            </div>
            <div class="card-body">

              <p>
                <strong>Time - </strong><br>
                <strong>Start: </strong>{{ obj.time_start }} <strong>End: </strong>{{ obj.time_end }}
              </p><hr>
              <p>
                <strong>Machine Hours - </strong><br>
                <strong>Start:</strong>  {{ obj.machine_start_hours }} <strong>End:</strong> {{ obj.machine_end_hours }}
              </p><hr>
              <p>
                <strong>Diesel Added: </strong> {{ obj.diesel_add }}
              </p>
              <p>
                <strong>Earth Moved Squared: </strong> {{ obj.earth_moved_squared }}
              </p>

              <p>
                <strong>Trenched: </strong> {{ obj.trench_length }}
              </p>



            </div>
            <div class="card-footer d-flex">
                <a v-if="obj.time_end && obj.machine_end_hours > 0"
                  @click="completeForm(obj.id)"
                  class="mdc-icon-button material-icons pe-5"
                >
                  done_outline
                </a>
              <formModal
                :form="form"
                :header="'Update plant'"
                :putApi="api + obj.id + '/'"
                :formObject="obj"
              />
              <a class="material-icons ps-5" @click="deleteWork(obj.id)">delete</a>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <h3>No Plant in action</h3>
      </template>
    </div>
  </div>

  <div v-else-if="$store.state.isAuthenticated == false">
    401 Forbidden
  </div>
</template>

<style>
  p {
    margin-bottom: 5px;
  }
</style>

<script js>
import { useStore } from 'vuex'
import formModal from '@/views/App/components/form_modal'

export default {
  name: 'dashboard',
  data() {
    return {
      store: useStore(),
      api: '/work/',
      responseData: {},
      querySet: {},
      errors: undefined,
      form: {},
    }
  },
  components: {
    formModal,
  },
  async mounted() {
    document.title = "Dashboard | Ronnie's Place"

    this.getDash()
    this.getForm()
  },
  methods: {
    async getDash() {
      this.responseData = await this.store.dispatch('getAPIResponse', this.api) //qs

      if (this.responseData.status == 200) {
        this.querySet = this.responseData.data
      } else {
        this.errors = this.responseData.data
      }
    },

    async getForm() {
      // try {
      let plantData = await this.store.dispatch('getAPIResponse', '/plant/') //plant dropdown qs
      let contractorData = await this.store.dispatch('getAPIResponse','/contractor/') //contractor dropdown qs
      let locationData = await this.store.dispatch('getAPIResponse','/location/') //location dropdown qs

      this.form = await this.store.dispatch('optionsAPIResponse', this.api)

      if (plantData.status == 200) {
        let fPlant = []
        plantData.data.forEach((element) => {
          fPlant.push({
            id: element.id,
            description: `${element.code}: ${element.description}`,
          })
        })
        this.form.plant.querySet = fPlant
      }
      if (contractorData.status == 200) {
        let fContractor = []
        contractorData.data.forEach((element) => {
          fContractor.push({
            id: element.id,
            description: `${element.description}`,
          })
        })
        this.form.contractor.querySet = fContractor
      }
      if (locationData.status == 200) {
        let fLocation = []
        locationData.data.forEach((element) => {
          fLocation.push({
            id: element.id,
            description: `${element.description}`,
          })
        })
        this.form.location.querySet = fLocation
      }

      // }
      // catch {

      // }
    },

    async completeForm(id) {
      const answer = window.confirm(
        'Are you sure you want to complete the work permit?'
      )
      if (answer) {
        const data = {
          api: `work/complete/${id}/`,
          data: {},
        }
        this.responseData = await this.store.dispatch('postAPIData', data)

        if (this.responseData.status == 202) {
          this.getDash()
        } else {
          alert("Please capture all fields of work permit before completing the work.")
          this.errors = this.responseData
        }
      }
    },
  
    async deleteWork(id) {
      const b = confirm("Are you sure you want to delete the job card?")
      const api = `${this.api}${id}/`
      if (b) {
        this.responseData = await this.store.dispatch('deleteAPIData', api)
        if (this.responseData.status == 204) {
          this.getDash()
        }
        else {
          alert(this.responseData.data)
        }
      }
    }
  },
}
</script>
