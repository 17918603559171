<template>
  <template v-if="field.label == 'ID'">
    <input :id=" fieldKey" :required="field.required" type="number" hidden />
  </template>
  <template v-else>
    <input v-if="field.type == 'integer'" type="number"      :placeholder="field.label" :value="value" :id="fieldKey" class="form-control" :required="field.required"  />
    <input v-else-if="field.type == 'decimal'" type="number"      :placeholder="field.label" :value="value" :id="fieldKey" class="form-control" :required="field.required"  />
    <input v-else-if="field.type == 'number'"  type="number"      :placeholder="field.label" :value="value" :id="fieldKey" class="form-control" :required="field.required"  />
    <input v-else-if="field.type == 'date'"    :type="field.type" :placeholder="field.label" :value="value" :id="fieldKey" class="form-control" :required="field.required"  />
    <input v-else-if="field.type == 'datetime'"    type="datetime-local" :placeholder="field.label" :value="$filters.dateInput(value)" :id="fieldKey" class="form-control" :required="field.required"  />
    <input v-else-if="field.type == 'time'"    type="time" :placeholder="field.label" :value="value" :id="fieldKey" class="form-control" :required="field.required"  />
    <input v-else-if="field.type == 'string'"  type="text"        :placeholder="field.label" :value="value" :id="fieldKey" class="form-control" :required="field.required"  :maxlength="field.max_length"/>

    <input v-else-if="field.type == 'boolean'"  type="checkbox" :id="fieldKey"  :checked="value" v-bind:true-value="1" v-bind:false-value="0"   class="form-check" :required="field.required" />

    <select v-else-if="field.querySet" mytype :placeholder="field.label" :id="fieldKey" class="form-select" :required="field.required" >
      <option 
        value
        mytype="select"
        class="form-control"
      >----------------</option>
      <template v-for="object in field.querySet"
        :key="object.id"
        :value="object.id"
        mytype="select"
        class="form-control"
      >
        <template v-if="value && value.id==object.id">
          <option selected :value="object.id">
            {{object.description}}
          </option>
        </template>
        <template v-else>
          <option :value="object.id">
            {{object.description}}
          </option>
        </template> 

      </template>
    </select>
  </template>
</template>

<script>
export default {
  name: 'createInput',
  props: {
    fieldKey: "",
    field: {},
    value: "",
  },
}
</script>
