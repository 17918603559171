import { createApp } from 'vue'
import router from './router'
import store from './store'
import App from './views/App/App.vue'
import axios from 'axios'
import VueExcelXlsx from "vue-excel-xlsx";

import './registerServiceWorker'
import { isDate } from '@vue/shared'

// const getAPI = axios.create({
//     baseURL: 'http://127.0.0.1:8000',
//     timeout: 1000000,
// })

axios.defaults.baseURL = 'http://127.0.0.1:8000'
axios.defaults.baseURL = 'https://plant.a-i-solutions.co.za'

const filters = {
  price(input) {
    if (isNaN(input)) {
      return '-'
    }
    return 'R' + input.toFixed(2)
  },
  dateTime(input) {
    if (isDate(input)) {
      return '-'
    }
    var date = new Date(input)
    return `${date.toLocaleDateString()}: ${date.toLocaleTimeString()}`
  },
  date(input) {
    if (isDate(input)) {
      return '-'
    }
    var date = new Date(input)
    return `${date.toLocaleDateString()}`
  },
  dateInput(input) {
    var date = undefined
    try {
      if (input == null) {
        return '-'
      } else {
        var date = new Date(input)
        if (date.getFullYear() == 1970) {
          return '-'
        }
        date.setHours(date.getHours() + 2)
        date = date.toISOString().replace('Z', '')
        return `${date}`
      }
    } catch {
      return '-'
    }
    return '-'
  },
}

const app = createApp(App)
app.config.globalProperties.$filters = filters

app
  .use(store)
  .use(router)
  .use(VueExcelXlsx)
  .mount('#app')